<template>
  <a v-show="isAllowed" class="btn btn-primary--inverse" :href="pdfUrl" target="_blank">
    <img src="@/assets/img/icons/document.svg" />
    <span>{{ buttonName }}</span>
  </a>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';

export default {
  name: 'booking-download-agreement-btn',
  props: {
    allowedActions: {
      required: true,
      validator: (value) => {
        if (value !== null) {
          return value.constructor.name === 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
  },
  mixins: [ButtonMixin],
  data() {
    return {
      actionName: 'DOWNLOADPDF',
      actionType: 'BOOKING',
      pdfUrl: '',
    };
  },
  watch: {
    async isAllowed(val) {
      console.log('is allwed change', val);
      if (val) {
        this.getAgreementUrl();
      } else {
        this.pdfUrl = '';
      }
    },
  },
  mounted() {
    if (this.isAllowed) {
      this.getAgreementUrl();
    } else {
      this.pdfUrl = '';
    }
  },
  methods: {
    async getAgreementUrl() {
      this.pdfUrl = await this.$store.dispatch('v2/booking/getUrlAgreement', this.applicationUuid);
    },
    async downloadAgreement() {
      await this.$store.dispatch('v2/booking/downloadAgreement', this.applicationUuid);
    },
  },
};
</script>

<style scoped></style>
